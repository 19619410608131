import { Helmet } from "react-helmet";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Layout from "Components/Layout/index";
import CommonClasses from "Styles/Common.module.css";
import CourseVideos from "./coursevideos";
import { useSearchParams } from "react-router-dom";
function Main() {
    const [Params] = useSearchParams();
    const Navigate = useNavigate();

    return (
        <Layout Active={"Courses"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Course Videos</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div className={CommonClasses["control-btns"]}>
                    <button
                        className={CommonClasses["button"]}
                        onClick={() => {
                            Navigate(-1);
                        }}>
                        Back
                    </button>
                    <button
                        className={CommonClasses["button"]}
                        onClick={() => {
                            Navigate(
                                `/Admin/Coursesvideos/Add?courseId=${Params.get(
                                    "courseId"
                                )}`
                            );
                        }}>
                        <BiPlus size={20} /> Create New Video
                    </button>
                </div>
                <div className={CommonClasses["wrapper"]}>
                    <h3>Videos</h3>
                    <CourseVideos />
                </div>
            </div>
        </Layout>
    );
}

export default Main;
