import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "../../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import { useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { NullCheck } from "../../../Components/validators";
import MainLoader from "../../../Components/loader/loader";
import { Error, Success, SuccessSwal, warningSwal } from "../../../Util/Toast";
window.Buffer = window.Buffer || require("buffer").Buffer;
function AddUsers() {
    const [loading, setLoading] = useState(false);
    const [params] = useSearchParams();
    const [edit, setEdit] = useState(false);
    const [mainLoad, setMainLoad] = useState(false);
    const Navigate = useNavigate();
    const location = useLocation();
    const { user } = location.state || {};
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    useEffect(() => {
        if (user) {
            setEdit(true);
            setValue("firstName", user?.firstName);
            setValue("lastName", user?.lastName);
            setValue("mobileNumber", user?.mobileNumber);
            setValue("emailId", user?.emailId);
            setValue("status", user.status);
        }
    }, [user]);

    const onSubmit = (data) => {
        if (edit) {
            let body = data;
            Services.EditUser(
                "PUT",
                JSON.stringify(body),
                token,
                params.get("id")
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Success(response.Message);
                        Navigate(-1);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        Error(response.Message);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            Services.AddUser("POST", JSON.stringify(data), token)
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Category Added", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const backButton = () => {
        Navigate(-1);
    };
    return (
        <Layout Active={"Users"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} User</title>
            </Helmet>
            {mainLoad && <MainLoader />}
            <div className={TutorClasses["Container"]}>
                <button onClick={backButton} className={TutorClasses["back"]}>
                    Back
                </button>
                <div className={TutorClasses["wrapper"]}>
                    <h3>{!edit ? "Add User" : "Update User"}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="firstName">
                                First Name{" "}
                            </label>
                            <input
                                {...register("firstName", {
                                    required: "this field is required",
                                    validate: NullCheck,
                                })}
                            />
                            {errors.firstName && (
                                <span>{errors.firstName.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="lastName">
                                Last Name{" "}
                            </label>
                            <input
                                {...register("lastName", {
                                    required: "this field is required",
                                    validate: NullCheck,
                                })}
                            />
                            {errors.lastName && (
                                <span>{errors.lastName.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="mobileNumber">
                                Mobile Number{" "}
                            </label>
                            <input
                                {...register("mobileNumber", {
                                    required: "this field is required",
                                    validate: NullCheck,
                                })}
                            />
                            {errors.mobileNumber && (
                                <span>{errors.mobileNumber.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="emailId">
                                Email ID{" "}
                            </label>
                            <input
                                {...register("emailId", {
                                    required: "this field is required",
                                    validate: NullCheck,
                                })}
                            />
                            {errors.emailId && (
                                <span>{errors.emailId.message}</span>
                            )}
                        </div>
                        {edit ? <div className={TutorClasses["form-control"]}>
                            <label htmlFor="Status">
                                Status
                            </label>
                            <select
                                {...register("status", {
                                    required: true,
                                })}>
                                <option value="1">Active</option>
                                <option value="2">Inactive</option>
                            </select>
                            {errors.status && (
                                <span>This field is required</span>
                            )}
                        </div> : ""}

                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default AddUsers;
