import React, { useRef, useState, useEffect } from "react";
import Modal from "react-modal";
import Styles from "./add.module.css";
import { Services } from "Services";
// import { useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import ReactPlayer from "react-player/lazy";

import { SuccessSwal, warningSwal } from "Util/Toast";
function Add({ addModal, setAddModal, link }) {
    return (
        <div>
            <Modal
                isOpen={addModal}
                contentLabel="Modal"
                onRequestClose={() => {
                    setAddModal(false);
                }}
                className={Styles["modal"]}
                overlayClassName={Styles["overlay"]}>
                <div className={Styles["wrapper"]}>
                    <ReactPlayer
                        url={link || ""}
                        controls
                        width="100%"
                        height="400px"
                    />
                </div>
            </Modal>
        </div>
    );
}

export default Add;
