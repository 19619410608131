import Categories from "pages/Categories/index";
import Login from "../pages/Login/index";
import Users from "pages/Users/index";
import Enrollments from "pages/Enrollments/index";
import Addcategory from "../pages/Categories/addCategory";
import CourseVideos from "pages/CourseVideos/index";
import AddEnrollment from "pages/Enrollments/AddEnrollments/AddEnrollement";
import AddUsers from "pages/Users/AddUser/AddUsers";
import AddVideo from "pages/CourseVideos/AddVideo/AddVideo";
import EnrolledUsers from 'pages/EnrolledUsers/index'
export const AccessKeys = {
  Categories: "Categories",
  CourseVideos: "CourseVideos",
  Users: "Users",
  Enrollments: "Enrollments",
  EnrollmentsUsers: "EnrollmentsUsers",
};

export const PageRoutes = [
  { path: "/", element: <Login />, AccessKey: AccessKeys.Login, Type: "read" },

  {
    path: "/Admin/Courses",
    element: <Categories />,
    AccessKey: AccessKeys.Categories,
    Type: "read",
  },
  {
    path: "/Admin/Courses/:course",
    element: <Addcategory />,
    AccessKey: AccessKeys.Categories,
    Type: "write",
  },
  {
    path: "/Admin/Courses/Enrolled_users",
    element: <EnrolledUsers />,
    AccessKey: AccessKeys.EnrollmentsUsers,
    Type: "write",
  },

  {
    path: "/Admin/Coursesvideos",
    element: <CourseVideos />,
    AccessKey: AccessKeys.CourseVideos,
    Type: "read",
  },

  {
    path: "/Admin/Coursesvideos/:video",
    element: <AddVideo />,
    AccessKey: AccessKeys.CourseVideos,
    Type: "write",
  },

  {
    path: "/Admin/Users",
    element: <Users />,
    AccessKey: AccessKeys.Users,
    Type: "read",
  },

  {
    path: "/Admin/Users/:user",
    element: <AddUsers />,
    AccessKey: AccessKeys.Users,
    Type: "write",
  },

  {
    path: "/Admin/Enrollment",
    element: <Enrollments />,
    AccessKey: AccessKeys.Enrollments,
    Type: "read",
  },

  {
    path: "/Admin/Enrollment/:enrole",
    element: <AddEnrollment />,
    AccessKey: AccessKeys.Enrollments,
    Type: "write",
  },
];
