import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "../../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import { useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { NullCheck } from "../../../Components/validators";
import MainLoader from "../../../Components/loader/loader";
import { Error, Success, SuccessSwal, warningSwal } from "../../../Util/Toast";
window.Buffer = window.Buffer || require("buffer").Buffer;
function Addcategory() {
    const [loading, setLoading] = useState(false);
    const [params] = useSearchParams();
    const [edit, setEdit] = useState(false);
    const [mainLoad, setMainLoad] = useState(false);
    const Navigate = useNavigate();
    const location = useLocation();
    const { course } = location.state || {};
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    useEffect(() => {
        if (course) {
            setValue("courseName", course.courseName);
            setValue("description", course.description);
            setValue("status", course.status);
            setEdit(true);
        }
    }, [course])

    const onSubmit = (data) => {
        if (edit) {
            let body = data;
            Services.EditCourse(
                "PUT",
                JSON.stringify(body),
                token,
                params.get("id")
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Success(response.Message);
                        Navigate(-1);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        Error(response.Message);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            Services.AddCourse("POST", JSON.stringify(data), token)
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Category Added", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    // const fileuploadDesign = (
    //     <div className={TutorClasses["add-item"]}>
    //         <BsCloudUpload className={TutorClasses["upload-icon"]} />
    //         <h5>Drag Your Files here</h5>
    //         <p>(Only *.jpeg ,*.png will be accepted)</p>
    //     </div>
    // );
    const backButton = () => {
        Navigate(-1);
    };
    return (
        <Layout Active={"Courses"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Course</title>
            </Helmet>
            {mainLoad && <MainLoader />}
            <div className={TutorClasses["Container"]}>
                <button onClick={backButton} className={TutorClasses["back"]}>
                    Back
                </button>
                <div className={TutorClasses["wrapper"]}>
                    <h3>{!edit ? "Add Course" : "Update Course"}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="courseName">
                                Course Name{" "}
                            </label>
                            <input
                                {...register("courseName", {
                                    required: "this field is required",
                                    validate: NullCheck,
                                })}
                            />
                            {errors.courseName && (
                                <span>{errors.courseName.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="description">
                                Description{" "}
                            </label>
                            <textarea
                                rows={5}
                                {...register("description", {
                                    required: "this field is required",
                                    validate: NullCheck,
                                })}
                            />
                            {errors.description && (
                                <span>{errors.description.message}</span>
                            )}
                        </div>
                        {edit ? <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                Status
                            </label>
                            <select
                                {...register("status", {
                                    required: true,
                                })}>
                                <option value="1">Active</option>
                                <option value="2">Inactive</option>
                            </select>
                            {errors.Status && (
                                <span>This field is required</span>
                            )}
                        </div> : ""}


                        {/* <div className={TutorClasses["file-upload-wrapper"]}>
                            <header>
                                <h3>
                                    Upload Image{" "}
                                    <span className="important">*</span>
                                </h3>
                            </header>
                            <FileUploader
                                classes={TutorClasses["upload-image"]}
                                multiple={false}
                                handleChange={filehandleChange}
                                name="file"
                                children={fileuploadDesign}
                            // types={fileTypes}
                            />
                        </div>

                        {base64 && (
                            <div
                                className={TutorClasses["file-images-wrapper"]}>
                                <div className={TutorClasses["image"]}>
                                    <img
                                        src={base64}
                                        alt="uploaded-data"
                                        onClick={() => {
                                            window.open(base64, "_blank");
                                        }}
                                    />
                                </div>
                            </div>
                        )} */}

                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Addcategory;
