import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { Success, warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";
import { AiFillDelete, AiOutlineFundView } from "react-icons/ai";
import { LuPen } from "react-icons/lu";
import { IoMdSearch } from "react-icons/io";
import Moment from "react-moment";

const Users = () => {
    const Navigate = useNavigate();
    const [showPaginate, setShowPagiate] = useState(true);
    const [offset, setOffset] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [params, setParams] = useSearchParams();
    const [search, setSearch] = useState("");
    const [forcepage, setForcePage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getAllCategories = (offset) => {
        Services.getUsers("GET", null, token, offset)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response?.Users);
                    if (
                        response.Users.length == 0 ||
                        response.UsersCount <= response?.Offset
                    ) {
                        setShowPagiate(false);
                    } else {
                        if (!showPaginate) {
                            setShowPagiate(true);
                        }
                        setOffset(response?.Offset);
                        setpageCount(
                            Math.ceil(
                                Number(response?.UsersCount) / Number(100)
                            )
                        );
                    }
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        setForcePage(Number(params.get("page")) - 1);

        getAllCategories({
            Offset: (Number(params.get("page")) - 1) * 100,
        });
        // eslint-disable-next-line
    }, []);
    const editHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        let user = data.find((c) => c.userId == id);
        Navigate(`/Admin/Users/edit?id=${id}`, { state: { user } });
    };

    const deleteHandler = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        Services.DeleteUser("DELETE", null, token, id)
            .then((res) => {
                if (res.Status === 1) {
                    Success(res.Message);
                    getAllCategories({ Offset: 0 });
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        // setcurrentPage(selectedObject.selected);
        // handleFetch();
        window.scrollTo({ top: 10, behavior: "instant" });
        setForcePage(selectedObject.selected);
        setParams({ page: selectedObject.selected + 1 });

        getAllCategories({ Offset: selectedObject.selected * 100 });
        setLoading(true);
    };
    const searchHandler = () => {
        setForcePage(0);
        setParams({ page: 1 });
        setLoading(true);
        getAllCategories({ Offset: 0, searchString: search });
    };
    const ClearHandler = () => {
        setParams({ page: 1 });
        setForcePage(0);
        setLoading(true);
        getAllCategories({ Offset: 0 });
        setSearch("");
    };
    return (
        <>
            <div className={CommonClasses["search_wrapper"]}>
                <div className={CommonClasses["search"]}>
                    <input
                        type="number"
                        value={search}
                        placeholder="search By Mobile"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <IoMdSearch className={CommonClasses["search_icon"]} />
                </div>
                <button onClick={searchHandler}>Search</button>
                <button
                    onClick={ClearHandler}
                    className={CommonClasses["clear"]}>
                    Clear
                </button>
            </div>
            {loading ? (
                <SkeletonLoad
                    TableHead={["Category Image", "Name", "Created", "Status"]}
                />
            ) : data.length > 0 ? (
                <div className={CommonClasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>last Name</th>
                                <th>mobile </th>
                                <th>Email</th>
                                <th>Status</th>
                                <th>Last Login</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr id={item.userId}>
                                    <td style={{ maxWidth: "150px" }}>
                                        {item.firstName}
                                    </td>
                                    <td>{item.lastName}</td>
                                    <td>{item.mobileNumber}</td>
                                    <td>{item.emailId}</td>
                                    <td>
                                        {item.status === 1 ? (
                                            <div
                                                className={
                                                    CommonClasses[
                                                        "Status-active"
                                                    ]
                                                }>
                                                <span>Active</span>
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    CommonClasses[
                                                        "Status-inactive"
                                                    ]
                                                }>
                                                <span>Inactive</span>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {item?.lastLoginDateTime !=
                                        "0000-00-00 00:00:00" ? (
                                            <>
                                                <Moment format="hh:mm a" utc>
                                                    {item?.lastLoginDateTime}
                                                </Moment>
                                                <br />
                                                <Moment format="DD-MM-YYY">
                                                    {item?.lastLoginDateTime}
                                                </Moment>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </td>
                                    <td>
                                        <div
                                            className={
                                                CommonClasses["Action-btns"]
                                            }>
                                            <button
                                                className={
                                                    CommonClasses["edit-btn"]
                                                }
                                                id={item.userId}
                                                onClick={editHandler}>
                                                <LuPen />
                                            </button>
                                            <button
                                                className={
                                                    CommonClasses["delete-btn"]
                                                }
                                                id={item.userId}
                                                onClick={deleteHandler}>
                                                <AiFillDelete />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    {" "}
                    <p>No Users Found</p>
                </div>
            )}
            {showPaginate && (
                <div
                    style={{
                        justifySelf: "right",
                    }}>
                    <ReactPaginate
                        pageCount={pageCount}
                        // pageRange={2}
                        forcePage={forcepage}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </div>
            )}
        </>
    );
};

export default Users;
