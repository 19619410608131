import { Error_Dark } from "./Util/Toast";
const httpcall = (url, method, body, token) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: method,

        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.Status === 0) {
                if (
                    res.Message === "Invalid Token" ||
                    res.Message === "Token expired"
                ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = "/";
                    Error_Dark("Token Expired");
                }
            }
            return res;
        });
};

const Login = (method, body) => {
    return httpcall("admin/login", method, body);
};

// courses
const getCategories = (method, body, token, queryparams) => {
    let api = `admin/courses`;

    if (queryparams) {
        const queryString = new URLSearchParams(queryparams).toString();
        api += `?${queryString}`;
    }
    return httpcall(api, method, body, token);
};
const AddCourse = (method, body, token) => {
    return httpcall(`admin/courses`, method, body, token);
};

const EditCourse = (method, body, token, id) => {
    return httpcall(`admin/${id}/courses`, method, body, token);
};

const DeleteCourse = (method, body, token, id) => {
    return httpcall(`admin/${id}/courses`, method, body, token);
};
const getCourseVideos = (method, body, token, OFFSET, courseId) => {
    return httpcall(
        `admin/course/videos?Offset=${OFFSET}&&courseId=${courseId}`,
        method,
        body,
        token
    );
};
const CourseVideosDelete = (method, body, token, id) => {
    return httpcall(`admin/course/videos/${id}`, method, body, token);
};

// without offset
const Users = (method, body, token) => {
    return httpcall(`admin/all-users`, method, body, token);
};

const Courses = (method, body, token) => {
    return httpcall(`admin/courses/all`, method, body, token);
};

// Users
const getUsers = (method, body, token, queryparams) => {
    let api = `admin/users/all`;

    if (queryparams) {
        const queryString = new URLSearchParams(queryparams).toString();
        api += `?${queryString}`;
    }
    return httpcall(api, method, body, token);
};

const AddUser = (method, body, token) => {
    return httpcall(`admin/users`, method, body, token);
};

const EditUser = (method, body, token, userId) => {
    return httpcall(`admin/${userId}/users`, method, body, token);
};

const DeleteUser = (method, body, token, userId) => {
    return httpcall(`admin/${userId}/users`, method, body, token);
};

// Enrollments
const getEnrollments = (method, body, token, queryparams) => {
    let api = `admin/course-enrollments`;

    if (queryparams) {
        const queryString = new URLSearchParams(queryparams).toString();
        api += `?${queryString}`;
    }
    console.log(queryparams);
    return httpcall(api, method, body, token);
};
const getEnrollmentsBYCourse = (method, body, token, offset, id) => {
    return httpcall(
        `admin/course-enrollments?courseId=${id}&&Offset=${offset}`,
        method,
        body,
        token
    );
};

const AddEnrollment = (method, body, token) => {
    return httpcall(`admin/course-enrollments`, method, body, token);
};

const DeleteEnrollment = (method, body, token, Id) => {
    return httpcall(`course-enrollments/${Id}`, method, body, token);
};
const AddCourseVideo = (method, body, token) => {
    return httpcall(`admin/course/videos`, method, body, token);
};
const updateCourseVideo = (method, body, token, id) => {
    return httpcall(`admin/course/videos/${id}`, method, body, token);
};

export const Services = {
    Login,
    getCategories,
    updateCourseVideo,
    AddCourseVideo,
    getUsers,
    getEnrollmentsBYCourse,
    getEnrollments,
    CourseVideosDelete,
    DeleteCourse,
    AddCourse,
    EditCourse,
    getCourseVideos,
    Users,
    Courses,
    AddUser,
    EditUser,
    DeleteUser,
    AddEnrollment,
    DeleteEnrollment,
};
