import topnavclasses from "./topNav.module.css";
import User from "../../Assets/user.svg";
import { HiOutlineLogout } from "react-icons/hi";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

function TopNav() {
    const Navigate = useNavigate();


    return (
        <div className={topnavclasses["TopNav"]}>
            <div className={topnavclasses["user"]}>
                <img src={User} alt="user" />
            </div>
        </div>
    );
}

export default TopNav;
