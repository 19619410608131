import Moment from "react-moment";
import { FaRegEdit } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Services } from "Services";
import { Success, SuccessSwal, warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import { useSearchParams } from "react-router-dom";
import CommonClasses from "Styles/Common.module.css";
import { AiFillDelete, AiOutlineFundView } from "react-icons/ai";
import { LuPen } from "react-icons/lu";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import Videomodel from "./VideoModal/add";
function CourseVideos() {
    const Navigate = useNavigate();
    const [params, setParams] = useSearchParams();
    const [VideoModal, setVideoModal] = useState(false);
    const [offset, setOffset] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [forcepage, setForcePage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showPaginate, setShowPagiate] = useState(true);
    const [link, setLink] = useState("");
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getAllCategories = (offset) => {
        Services.getCourseVideos(
            "GET",
            null,
            token,
            offset,
            params.get("courseId")
        )
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response?.CourseVideos);
                    if (
                        response.CourseVideos.length == 0 ||
                        response.VideosCount <= response?.Offset
                    ) {
                        setShowPagiate(false);
                    } else {
                        if (!showPaginate) {
                            setShowPagiate(true);
                        }
                        setOffset(response?.Offset);
                        setpageCount(
                            Math.ceil(
                                Number(response?.VideosCount) / Number(100)
                            )
                        );
                    }
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getAllCategories((Number(params.get("page")) - 1) * 100);
        setForcePage(Number(params.get("page")) - 1);
    }, []);
    const editHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        let video = data.find((v) => v.videoId == id);
        console.log(video);
        Navigate(
            `/Admin/Coursesvideos/edit?videoId=${id}&&courseId=${params.get(
                "courseId"
            )}`,
            {
                state: { video },
            }
        );
    };

    const deleteHandler = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        Services.CourseVideosDelete("DELETE", null, token, id)
            .then((res) => {
                if (res.Status === 1) {
                    Success(res.Message);
                    getAllCategories(0);
                    setForcePage(0);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);

        setParams({
            page: selectedObject.selected + 1,
            courseId: params.get("courseId"),
        });

        window.scrollTo({ top: 10, behavior: "instant" });
        setForcePage(selectedObject.selected);
        getAllCategories(selectedObject.selected * 100);
        setLoading(true);
    };
    return (
        <>
            {VideoModal && (
                <Videomodel
                    addModal={VideoModal}
                    setAddModal={setVideoModal}
                    link={link}
                />
            )}
            {loading ? (
                <SkeletonLoad
                    TableHead={["Category Image", "Name", "Created", "Status"]}
                />
            ) : data.length > 0 ? (
                <div className={CommonClasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Link</th>
                                <th>created At</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr id={item.videoId}>
                                    <td style={{ maxWidth: "150px" }}>
                                        {item.topicName}
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setLink(item?.videoURL);
                                                setVideoModal(true);
                                            }}>
                                            Click
                                        </button>
                                    </td>
                                    <td>
                                        {moment(item.createdAt).format(
                                            "DD-MM-YYYY"
                                        )}
                                    </td>
                                    <td>
                                        {item.status === 1 ? (
                                            <div
                                                className={
                                                    CommonClasses[
                                                        "Status-active"
                                                    ]
                                                }>
                                                <span>Active</span>
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    CommonClasses[
                                                        "Status-inactive"
                                                    ]
                                                }>
                                                <span>Inactive</span>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        <div
                                            className={
                                                CommonClasses["Action-btns"]
                                            }>
                                            <button
                                                className={
                                                    CommonClasses["edit-btn"]
                                                }
                                                id={item.videoId}
                                                onClick={editHandler}>
                                                <LuPen />
                                            </button>
                                            <button
                                                className={
                                                    CommonClasses["delete-btn"]
                                                }
                                                id={item.videoId}
                                                onClick={deleteHandler}>
                                                <AiFillDelete />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    {" "}
                    <p>No Videos Found</p>
                </div>
            )}
            {showPaginate && (
                <div
                    style={{
                        justifySelf: "right",
                    }}>
                    <ReactPaginate
                        pageCount={pageCount}
                        // pageRange={2}
                        forcePage={forcepage}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </div>
            )}
        </>
    );
}

export default CourseVideos;
