import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { Success, SuccessSwal, warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";
import CommonClasses from "Styles/Common.module.css";
import { AiFillDelete, AiOutlineFundView } from "react-icons/ai";
import { LuPen } from "react-icons/lu";
import Moment from "react-moment";
function Tutor() {
    const Navigate = useNavigate();
    const [params, setParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [showPaginate, setShowPagiate] = useState(true);

    const [data, setData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [forcepage, setForcePage] = useState(0);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getAllCategories = (queryparams) => {
        Services.getCategories("GET", null, token, queryparams)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    if (
                        response.Courses.length == 0 ||
                        response.CoursesCount <= response?.Offset
                    ) {
                        setShowPagiate(false);
                    } else {
                        if (!showPaginate) {
                            setShowPagiate(true);
                        }
                        setOffset(response?.Offset);
                        setpageCount(
                            Math.ceil(
                                Number(response?.CoursesCount) / Number(100)
                            )
                        );
                    }
                    setData(response?.Courses);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        setForcePage(Number(params.get("page")) - 1);
        getAllCategories({
            Offset: (Number(params.get("page")) - 1) * 100,
        });
    }, []);

    const editHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        let course = data.find((c) => c.courseId == id);
        // console.log("first", course);
        Navigate(`/Admin/Courses/edit?id=${id}`, { state: { course } });
    };

    const deleteHandler = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        Services.DeleteCourse("DELETE", null, token, id)
            .then((res) => {
                if (res.Status === 1) {
                    Success(res.Message);
                    getAllCategories({ Offset: 0 });
                    setForcePage(0);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const CourseVideos = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        Navigate(`/Admin/Coursesvideos?courseId=${id}&&page=1`);
    };
    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        // setcurrentPage(selectedObject.selected);
        // handleFetch();
        window.scrollTo({ top: 10, behavior: "instant" });
        setForcePage(selectedObject.selected);
        setParams({ page: selectedObject.selected + 1 });

        getAllCategories({ Offset: selectedObject.selected * 100 });
        setLoading(true);
    };
    return (
        <>
            {loading ? (
                <SkeletonLoad
                    TableHead={["Category Image", "Name", "Created", "Status"]}
                />
            ) : data.length > 0 ? (
                <div className={CommonClasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Course Name</th>
                                <th>Description</th>
                                <th>created At</th>
                                <th>Enrollments Count </th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr id={item.courseId}>
                                    <td style={{ maxWidth: "150px" }}>
                                        {item.courseName}
                                    </td>
                                    <td style={{ maxWidth: "16rem" }}>
                                        {item.description}
                                    </td>

                                    <td>
                                        <Moment format="DD-MM-YYYY ">
                                            {item.createdAt}
                                        </Moment>
                                    </td>
                                    <td
                                        style={{
                                            textDecoration: "underline",
                                            fontWeight: "bold",
                                        }}
                                        onClick={() =>
                                            Navigate(
                                                `/Admin/Courses/Enrolled_users?courseId=${item?.courseId}&&page=1`
                                            )
                                        }>
                                        {item.EnrollmentsCount}
                                    </td>
                                    <td>
                                        {item.status === 1 ? (
                                            <div
                                                className={
                                                    CommonClasses[
                                                        "Status-active"
                                                    ]
                                                }>
                                                <span>Active</span>
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    CommonClasses[
                                                        "Status-inactive"
                                                    ]
                                                }>
                                                <span>Inactive</span>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        <div
                                            className={
                                                CommonClasses["Action-btns"]
                                            }>
                                            <button
                                                className={
                                                    CommonClasses["view-btn"]
                                                }
                                                id={item.courseId}
                                                onClick={CourseVideos}>
                                                <AiOutlineFundView />
                                            </button>
                                            <button
                                                className={
                                                    CommonClasses["edit-btn"]
                                                }
                                                id={item.courseId}
                                                onClick={editHandler}>
                                                <LuPen />
                                            </button>
                                            <button
                                                className={
                                                    CommonClasses["delete-btn"]
                                                }
                                                id={item.courseId}
                                                onClick={deleteHandler}>
                                                <AiFillDelete />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    {" "}
                    <p>No Categories Found</p>
                </div>
            )}
            {showPaginate && (
                <div
                    style={{
                        justifySelf: "right",
                    }}>
                    <ReactPaginate
                        pageCount={pageCount}
                        // pageRange={2}
                        forcePage={forcepage}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </div>
            )}
        </>
    );
}

export default Tutor;
