import uuid from "react-uuid";
import { FaBookReader, FaUser } from "react-icons/fa";
import { IoIosBook } from "react-icons/io";

export const menuItems = [
  {
    id: uuid(),
    multiple: false,
    name: "Courses",
    menuLink: "/Admin/Courses?page=1",
    active: "Courses",
    icon: IoIosBook,
  },

  {
    id: uuid(),
    multiple: false,
    name: "Users",
    menuLink: "/Admin/Users?page=1",
    active: "Users",
    icon: FaUser,
  },

  {
    id: uuid(),
    multiple: false,
    name: "Enrollment",
    menuLink: "/Admin/Enrollment?page=1",
    active: "Enrollment",
    icon: FaBookReader,
  },
];
