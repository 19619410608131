import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "../../../Components/Layout";
import TutorClasses from "./inedx.module.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import { useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { NullCheck } from "../../../Components/validators";
import MainLoader from "../../../Components/loader/loader";
import { Error, Success, SuccessSwal, warningSwal } from "../../../Util/Toast";
window.Buffer = window.Buffer || require("buffer").Buffer;
function AddEnrollment() {
    const [loading, setLoading] = useState(false);
    const [params] = useSearchParams();
    const [edit, setEdit] = useState(false);
    const [mainLoad, setMainLoad] = useState(false);
    const [users, setUsers] = useState([]);
    const [courses, setCourses] = useState([]);
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };


    useLayoutEffect(() => {
        if (params.get("id")) {
            setMainLoad(true);
        }
        // eslint-disable-next-line
    }, []);


    const onSubmit = (data) => {

        Services.AddEnrollment("POST", JSON.stringify(data), token)
            .then((response) => {
                if (response.Status === 1) {
                    Navigate(-1);
                    SuccessSwal("Enrollment Added", response.Message);
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };


    const backButton = () => {
        Navigate(-1);
    };
    useEffect(() => {

        getUsers();
        getCourses();
    }, []);

    const getUsers = () => {
        Services.Users("GET", null, token).then((res) => {
            if (res.Status === 1) {
                setUsers(res.Users);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const getCourses = () => {
        Services.Courses("GET", null, token).then((res) => {
            if (res.Status === 1) {
                setCourses(res.Courses);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <Layout Active={"Enrollment"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Enrollment</title>
            </Helmet>
            {mainLoad && <MainLoader />}
            <div className={TutorClasses["Container"]}>
                <button onClick={backButton} className={TutorClasses["back"]}>
                    Back
                </button>
                <div className={TutorClasses["wrapper"]}>
                    <h3>Add Enrollment</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="courseId">
                                Course{" "}
                            </label>
                            <select
                                {...register("courseId", {
                                    required: "this field is required",
                                    validate: NullCheck,
                                })}
                            >
                                <option value="" selected disabled> Selcet Course</option>
                                {
                                    courses.map((course) => (
                                        <option key={course.courseId} value={course.courseId}>
                                            {course.courseName}
                                        </option>
                                    ))
                                }

                            </select>

                            {errors.courseId && (
                                <span>{errors.courseId.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="userId">
                                User{" "}
                            </label>
                            <select
                                {...register("userId", {
                                    required: "this field is required",
                                    validate: NullCheck,
                                })}
                            >
                                <option value="" selected disabled>Select user</option>
                                {
                                    users.map((user) => (
                                        <option key={user.userId} value={user.userId}>
                                            {user.firstName} {user.lastName}
                                        </option>
                                    ))
                                }
                            </select>
                            {errors.userId && (
                                <span>{errors.userId.message}</span>
                            )}
                        </div>

                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default AddEnrollment;
