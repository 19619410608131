import React from "react";
import Users from "./users";
import { useNavigate } from "react-router-dom";
import Layout from "Components/Layout/index";
import CommonClasses from "Styles/Common.module.css";
import { Helmet } from "react-helmet";
import { BiPlus } from "react-icons/bi";

const Main = () => {
    const Navigate = useNavigate();
    return (
        <Layout Active={"Courses"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Users</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                {/*    <div className={CommonClasses["control-btns"]}>
                    <button
                        className={CommonClasses["button"]}
                        onClick={() => {
                            Navigate("/Admin/Users/Add");
                        }}>
                        <BiPlus size={20} /> Create New User
                    </button>
                </div> */}
                <div className={CommonClasses["wrapper"]}>
                    <h3>Users</h3>
                    <Users />
                </div>
            </div>
        </Layout>
    );
};

export default Main;
