import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "../../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import S3FileUpload from "react-s3";
import { config } from "../../../s3config";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import { useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { NullCheck } from "../../../Components/validators";
import MainLoader from "../../../Components/loader/loader";
import { Error, Success, SuccessSwal, warningSwal } from "../../../Util/Toast";
import ReactPlayer from "react-player/lazy";
import { IoMdClose } from "react-icons/io";
// import { dirname } from "path";
// import { useSearchParams } from "react-router-dom";
window.Buffer = window.Buffer || require("buffer").Buffer;
function AddVideo() {
    const [loading, setLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [params] = useSearchParams();
    const [edit, setEdit] = useState(false);
    const [base64, setbase64] = useState("");
    const [mainLoad, setMainLoad] = useState(false);
    const Navigate = useNavigate();
    const location = useLocation();
    const { video } = location.state || {};
    const {
        register,
        handleSubmit,
        reset,
        setValue,

        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    useEffect(() => {
        console.log(video);
        if (video) {
            setEdit(true);
            setValue("topicName", video.topicName);
            setVideoUrl(video?.videoURL);
            setValue("status", video.status);
        }
    }, [video]);

    function filehandleChange(files) {
        console.log(files.type);

        if (files.type === "video/mp4") {
            const timestamp = Date.now();
            const newfile = new File(
                [files],
                `${timestamp}.${String(files.type).split("/")[1]}`
            );

            S3FileUpload.uploadFile(newfile, {
                ...config,
                dirName: `course-videos/${params.get("courseId")}`,
            })
                .then((res) => {
                    console.log(res);
                    setVideoUrl(res.location);
                    setValue(
                        "videoURL",
                        `/${params.get("courseId")}/${timestamp}.${
                            String(files.type).split("/")[1]
                        }`
                    );
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                });
        } else {
            alert(`Please upload MP4  Files only`);
            return false;
        }
        return true;
    }

    const onSubmit = (data) => {
        if (edit) {
            let body = data;
            if (!body?.videoURL) {
                delete body?.videoURL;
            }
            console.log(body);

            Services.updateCourseVideo(
                "PUT",
                JSON.stringify({ ...body }),
                token,
                params.get("videoId")
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Success(response.Message);
                        Navigate(-1);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        Error(response.Message);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            if (!data?.videoURL) {
                alert("video is required");
                return;
            }
            console.log(data);

            Services.AddCourseVideo(
                "POST",
                JSON.stringify({ ...data, courseId: params.get("courseId") }),
                token
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Category Added", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    const fileuploadDesign = (
        <div className={TutorClasses["add-item"]}>
            <BsCloudUpload className={TutorClasses["upload-icon"]} />
            <h5>Drag Your Files here</h5>
            <p>(Only *.MP4 will be accepted)</p>
        </div>
    );
    const backButton = () => {
        Navigate(-1);
    };
    const DeleteHandler = () => {
        setVideoUrl("");
        setValue("videoURL", "");
    };
    return (
        <Layout Active={"Courses"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Video</title>
            </Helmet>
            {mainLoad && <MainLoader />}
            <div className={TutorClasses["Container"]}>
                <button onClick={backButton} className={TutorClasses["back"]}>
                    Back
                </button>
                <div className={TutorClasses["wrapper"]}>
                    <h3>{!edit ? "Add Video" : "Update Video"}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="topicName">Topic Name </label>
                            <input
                                {...register("topicName", {
                                    required: "this field is required",
                                    validate: NullCheck,
                                })}
                            />
                            {errors.topicName && (
                                <span>{errors.topicName.message}</span>
                            )}
                        </div>
                        {edit ? (
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="First Name">Status</label>
                                <select
                                    {...register("status", {
                                        required: true,
                                    })}>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                                {errors.status && (
                                    <span>This field is required</span>
                                )}
                            </div>
                        ) : (
                            ""
                        )}

                        <div className={TutorClasses["file-upload-wrapper"]}>
                            {videoUrl ? (
                                <div className={TutorClasses["FileL"]}>
                                    <ReactPlayer
                                        url={videoUrl || ""}
                                        controls
                                        width="100%"
                                        height="400px"
                                    />
                                    <button
                                        onClick={DeleteHandler}
                                        type="button">
                                        <IoMdClose color="white" />
                                    </button>
                                </div>
                            ) : (
                                <FileUploader
                                    classes={TutorClasses["upload-image"]}
                                    multiple={false}
                                    handleChange={filehandleChange}
                                    name="file"
                                    children={fileuploadDesign}
                                    // types={fileTypes}
                                />
                            )}
                        </div>

                        {base64 && (
                            <div
                                className={TutorClasses["file-images-wrapper"]}>
                                <div className={TutorClasses["image"]}>
                                    <img
                                        src={base64}
                                        alt="uploaded-data"
                                        onClick={() => {
                                            window.open(base64, "_blank");
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default AddVideo;
