import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { Success, warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import { AiFillDelete, AiOutlineFundView } from "react-icons/ai";
import { LuPen } from "react-icons/lu";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";

import { useSearchParams } from "react-router-dom";
const Enrollments = () => {
    const Navigate = useNavigate();
    const [params, setParams] = useSearchParams();
    const [showPaginate, setShowPagiate] = useState(true);
    const [search, setSearch] = useState("");

    const [offset, setOffset] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [forcepage, setForcePage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getAllCategories = (queryparams) => {
        Services.getEnrollments("GET", null, token, queryparams)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response?.UserCourseEnrollment);
                    if (
                        response.UserCourseEnrollment.length == 0 ||
                        response.EnrollmentCount <= response?.Offset
                    ) {
                        setShowPagiate(false);
                    } else {
                        if (!showPaginate) {
                            setShowPagiate(true);
                        }
                        setOffset(response?.Offset);
                        setpageCount(
                            Math.ceil(
                                Number(response?.EnrollmentCount) / Number(100)
                            )
                        );
                    }
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        setForcePage(Number(params.get("page")) - 1);

        getAllCategories({
            Offset: (Number(params.get("page")) - 1) * 100,
        });
        // eslint-disable-next-line
    }, []);

    const deleteHandler = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        Services.DeleteEnrollment("DELETE", null, token, id)
            .then((res) => {
                if (res.Status === 1) {
                    Success(res.Message);
                    getAllCategories({ Offset: 0 });
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        // setcurrentPage(selectedObject.selected);
        // handleFetch();
        window.scrollTo({ top: 10, behavior: "instant" });
        setForcePage(selectedObject.selected);
        setParams({ page: selectedObject.selected + 1 });

        getAllCategories({ Offset: selectedObject.selected * 100 });
        setLoading(true);
    };
    const searchHandler = () => {
        setForcePage(0);
        setParams({ page: 1 });
        setLoading(true);
        getAllCategories({ Offset: 0, searchString: search });
    };
    const ClearHandler = () => {
        setParams({ page: 1 });
        setForcePage(0);
        setLoading(true);
        getAllCategories({ Offset: 0 });
        setSearch("");
    };
    return (
        <>
            <div className={CommonClasses["search_wrapper"]}>
                <div className={CommonClasses["search"]}>
                    <input
                        type="number"
                        value={search}
                        placeholder="search By Mobile"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <IoMdSearch className={CommonClasses["search_icon"]} />
                </div>
                <button onClick={searchHandler}>Search</button>
                <button
                    onClick={ClearHandler}
                    className={CommonClasses["clear"]}>
                    Clear
                </button>
            </div>
            {loading ? (
                <SkeletonLoad
                    TableHead={["Category Image", "Name", "Created", "Status"]}
                />
            ) : data.length > 0 ? (
                <div className={CommonClasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>mobile </th>
                                <th>course</th>
                                <th>date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr id={item.courseEnrollmentId}>
                                    <td>{item.firstName}</td>
                                    <td>{item.mobileNumber}</td>
                                    <td>{item.courseName}</td>
                                    <td>
                                        {moment(item.createdAt).format(
                                            "DD-MM-YYYY"
                                        )}
                                    </td>
                                    <td>
                                        <div
                                            className={
                                                CommonClasses["Action-btns"]
                                            }>
                                            <button
                                                className={
                                                    CommonClasses["delete-btn"]
                                                }
                                                id={item.courseEnrollmentId}
                                                onClick={deleteHandler}>
                                                <AiFillDelete />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    {" "}
                    <p>No Enrollments Found</p>
                </div>
            )}
            {showPaginate && (
                <div
                    style={{
                        justifySelf: "right",
                    }}>
                    <ReactPaginate
                        pageCount={pageCount}
                        // pageRange={2}
                        forcePage={forcepage}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </div>
            )}
        </>
    );
};

export default Enrollments;
